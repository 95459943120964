<template>
  <div style="width: 544px;margin: 0 auto">
    <el-row :gutter="20">
      <draggable :options="{draggable:'.draggable', disabled: false, animation: 300}" @update="_changePosition">
        <el-col :span="12" v-for="(item, index) in module" :key="item.id" class="draggable">
          <!--          <div-->
          <!--            style="height: 0.3rem;width: 260px;background:rgba(16, 105, 178, 1);line-height: 0.3rem;margin-top: 0.2rem;position:relative;">-->
          <!--            <span style="font-size: 0.14rem;color: #FFFFFF;padding-left: 0.12rem">{{ item.label }}</span>-->
          <!--            <i style="position:absolute;right: 0.12rem;">-->
          <!--              <img src="../../../assets/img/city/triangle.png" style="width: 0.08rem;height: 0.05rem;"/>-->
          <!--            </i>-->
          <!--           -->
          <!--          </div>-->
          <div style="margin-top: 0.2rem">
            <select style="height: 0.3rem;width: 260px;background:#0C508C;color:#FFFFFF;" v-model="item.value">
              <option value="">隐藏</option>
              <option v-for="(item, index) in defaultModuleList" :key="item.templateCode" :value="index + 1">
                {{ item.templateName }}
              </option>
            </select>
          </div>
          <div class="module-item">
            <img src="../../../assets/img/city/student.png" style="width: 0.4rem;height: 0.4rem;margin-top: 17px"/>
          </div>
        </el-col>
      </draggable>
    </el-row>
    <div style="text-align: center;margin-top: 42px;padding-bottom: 0.4rem" class="dialog">
      <el-button icon="el-icon-edit" type="primary" @click="formSubmit">修改
      </el-button>
      <el-button icon="" type="primary" @click="close">取消</el-button>
    </div>
  </div>
</template>
<script>
import draggable from 'vuedraggable'

export default {

  components: {
    draggable
  },
  data () {
    return {
      module: [],
      defaultModuleList: [],
      userSettingModuleList: []
    }
  },
  mounted () {
    this.getModuleList()
  },
  methods: {

    init (userSettingModuleList) {
      this.userSettingModuleList = userSettingModuleList
      this._buildConfig()
    },

    close () {
      this.$emit('close')
    },

    _buildConfig () {
      const module = []
      this.defaultModuleList.forEach((item, index) => {
        module.push({
          value: 0,
          id: 'item-' + index
        })
      })
      this.userSettingModuleList.forEach(item => {
        module[item.orderNum].value = this._getModuleIndex(item.templateCode)
      })
      this.module = module
    },

    _getModuleIndex (templateCode) {
      let index = this.defaultModuleList.findIndex(item => item.templateCode === templateCode)
      return ++index
    },

    getModuleList () {
      // const res = await this.http.post('/district-command-center/settings/getTemplate')
      // const res = JSON.parse('{"msg":null,"data":[{"templateName":"实时在校数据","orderNum":0,"templateCode":"inschoolData","templateId":"79cdc30996b14a33ac8b021e9af74e1f"},{"templateName":"校园报警","orderNum":0,"templateCode":"alarmInfoData","templateId":"9206f4f0e3be4c83818b33bd5df0dc8d"},{"templateName":"体温监测","orderNum":0,"templateCode":"temperatureData","templateId":"e61374233aeb4d5189415beb997bf1dc"},{"templateName":"云上党建","orderNum":0,"templateCode":"partyBuildData","templateId":"6bfd89e8b3c44852b6c9313c2892f9c5"},{"templateName":"宿舍管理","orderNum":0,"templateCode":"dormData","templateId":"da3a8ca438b1456593d760d1f288884e"},{"templateName":"课堂考勤","orderNum":0,"templateCode":"studentCheckData","templateId":"0bffc7bb1578499a8ad5a57eee505dfc"}],"status":200}')
      if (res.status !== 200) return
      this.defaultModuleList = res.data
      this._buildConfig()
    },

    async formSubmit () {
      const form = {}
      form.templateVOS = []
      const box = []
      let bValidateSuccess = true
      this.module.forEach((item, index) => {
        if (item.value) {
          if (box.indexOf(item.value) > -1) {
            bValidateSuccess = false
            return this.$message.error('不允许重复模块')
          }
          box.push(item.value)
          let template = {}
          template = this.defaultModuleList[item.value - 1]
          template.orderNum = index
          form.templateVOS.push(template)
        }
      })
      if (!bValidateSuccess) return
      const res = await this.http.post('/district-command-center/settings/add/templates/byEduId', form)
      if (res.status !== 200) return this.$message.error(res.message)
      this.$message.success('设置成功')
      this.$emit('change', form.templateVOS)
    },

    // 改变位置
    _changePosition (e) {
      const a = e.newIndex
      const b = e.oldIndex
      if (a < 0 || b < 0) return
      if (a > this.module.length - 1 || b > this.module.length - 1) return
      this.module[a] = this.module.splice(b, 1, this.module[a])[0]
    }
  }
}
</script>
<style scoped>
.module-item {
  height: 0.74rem;
  width: 260px;
  margin-top: 4px;
  background-image: url(../../../assets/img/city/module-item.png);
  background-size: cover;
  text-align: center;
}

.dialog button {
  background: #136DBB;
  border-color: #136DBB;
}
</style>
